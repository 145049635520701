footer {
    padding: 80px 0px;
    background: rgb(51,155,158);
    background: linear-gradient(145deg, #2E3243 0%, #2F77D6 100%);
  }
  
  footer .left-text-content p {
    color: #fff;
    font-size: 14px;
  }
  
  footer .left-text-content a {
    color: #fff;
  }
  
  footer .right-text-content {
    float: right;
  }
  
  footer .right-text-content p {
    color: #fff;
    font-size: 14px;
    margin-right: 15px;
    text-transform: uppercase;
  }
  
  footer .right-text-content ul li {
    display: inline-block;
  }
  
  footer .right-text-content ul li a {
    width: 32px;
    height: 32px;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 50%;
    color: #5fb759;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  
  footer .right-text-content ul li a:hover {
    background-color: #5fb759;
    color: #fff;
  }
  
  @media (max-width: 992px) {
    footer .left-text-content p {
      text-align: center;
      margin-bottom: 30px;
    }
    footer .right-text-content {
      float: none;
      text-align: center;
    }
  }