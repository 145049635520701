.Align-right {
  text-align: right;
}

.Align-left {
  text-align: left;
}

.Align-center {
  text-align: center;
}

body {
  background-color: #fafafa !important;
}

.Center {
  margin: auto;
}

.App {
  font-family: Arial;
}

a:hover {
  cursor: pointer;
}

.main-nav a:hover,
a.Button:hover{
  text-decoration: none;
}

.App-header .main-nav .Navigation li a.Button:hover,
a.Button:hover {
  background-color: #dbdbdb;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

/*
----------------------
HEADER
----------------------
*/

header {
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  min-height: 80px;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

header.Header--fixed {
  position: fixed;
  background: linear-gradient(145deg, #2E3243 0%, #2F77D6 100%);
  box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
}

header:not(.Header--fixed) {
  position: absolute;
}

header .main-nav .logo {
  line-height: 80px;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  float: left;
}

header .main-nav .Navigation {
  float: right;
  margin-top: 20px;
  margin-right: 0px;
  display: flex;
}

header .main-nav .Navigation li {
  padding-left: 20px;
  padding-right: 20px;
}

header .main-nav .Navigation li a {
  font-weight: 500;
  font-size: 13px;
  color: #7a7a7a;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

header .main-nav .Navigation li a {
  color: #fff;
}

header .main-nav .Navigation li a.active {
  border-bottom: solid white 2px;
}

header .main-nav .Navigation li a.Button,
a.Button {
  text-transform: none;
  font-weight: 500;
  font-size: large;
  width: 125px;
  text-align: center;
  padding: 10px 16px;
  color: #334AC0 !important;
  background-color: white;
  border-radius: 150px;
  margin: auto;
  margin-left: 5px;
  box-shadow: 0 5px 5px rgba(0,0,0,0.22);
  transition: all .2s;
}

header .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 23px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

header .main-nav .menu-trigger span,
header .main-nav .menu-trigger span:before,
header .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #334AC0;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

header .main-nav .menu-trigger span:before,
header .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #334AC0;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

header .main-nav .menu-trigger span:before,
header .main-nav .menu-trigger span:after {
  content: "";
}

header .main-nav .menu-trigger span {
  top: 16px;
}

header .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

header .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

@media (max-width: 768px) {
  header .main-nav .logo {
    color: #334AC0;
  }
  header .main-nav .Navigation li a.active {
    color: #334AC0 !important;
  }
  header {
    background-color: #f7f7f7 !important;
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
  }
  header .container {
    padding: 0px;
  }
  header .menu-trigger {
    display: block !important;
  }
  header .main-nav .Navigation {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  header .main-nav .Navigation li:first-child {
    border-top: 1px solid #eee;
  }
  header .Navigation {
    margin-top: 80px !important;
  }
  header .main-nav .Navigation li {
    width: 100%;
    background: #f7f7f7;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  header .main-nav .Navigation li a {
    height: 50px !important;
    display: block;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #334AC0 !important;
  }
  header .main-nav .Navigation li:hover {
    background: #334AC0 !important;
  }
  header .main-nav .Navigation li:hover > a{
    background: #334AC0 !important;
    color: white !important;
  }
  header .main-nav .Navigation li:last-child {
    display: none;
  }
  header .main-nav {
    overflow: hidden;
  }
}

/*
---------------------
Main Banner
---------------------
*/

.Button-container {
  padding-top: 50px;
  margin:auto;
}

.Button-container a {
  margin: 4vw 6vw !important;
  font-size: larger;
  display: block;
}

.Desktop-only {
  display: none;
}

.Mobile-only {
  display: block;
}

.Button-container > div a:first-child {
  background-color: transparent;
  border: white solid;
  color: white !important;
}

.MainBanner {
  height: 95vh;
  background: linear-gradient(145deg, #2E3243 0%, #2F77D6 100%);
}

.MainBanner > div {
  height: 100%;
  background-image: url('/images/br.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-attachment: fixed;
}

.MainBanner .container {
  height: 100%;
}

.Arrow {
  position: absolute;
  width: 100%;
  margin-top: -30px;
  z-index: 10;
}

.Arrow a {
  display: flex;
  text-align: center;
  line-height: 60px;
  color: #fff;
}

.Slogan {
  margin: 5vw;
  margin-top: 30vh;
  color: white;
  font-size: 6vmin;
  width: 100%;
}

.Slogan img {
  width: 100%;
}

.Slogan a {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  box-shadow: 0 5px 5px rgba(0,0,0,0.22);
}

.Slogan-headline {
  text-align: center;
}

.Main-button {
  background-color: white;
  border: solid 2px #334AC0;
  font-size: 3vh;
}

.Main-graphic {
  margin: auto 0px;
}

.Main-graphic img {
  float: right;
  height: 65vh;
}

.Arrow {
  display: flex;
}
.Arrow img {
  height: 2vh;
  margin: auto;
}

#Toolkit {
  padding-top: 10vh;
}

#About .container {
  padding: 10vh 0px;
}
#About .container .row:first-child {
  margin-bottom: 4vh;
}

.Section .Graphic {
  margin: auto;
}

.Section .Graphic-description {
  margin: auto;
  text-align: center;
}

.Section .Graphic-description > div {
  display: inline-block;
}

.Section .Graphic-description h1 {
  color: #334AC0;
}

.Section .Graphic-description ul li {
  color: #026873;
}

@media (max-width: 1000px) {
  .Section#About .Graphic-description,
  .Section#About .col-6 {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 4vh 0;
  }

  .Section#About .Graphic-description > div {
    text-align: center;
  }

}

@keyframes play54 {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -12852px 0px;
  }
}
.shapeshifter.Phone {
  background-image: url('/images/phone.svg');
  animation-duration: 900ms;
  animation-timing-function: steps(54);
  animation-fill-mode: forwards;
  width: 238px;
  height: 462px;
  background-repeat: no-repeat;
}
.shapeshifter.Phone.play {
  animation-name: play54;
}

@keyframes playComputer {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -23760px 0px;
  }
}
.shapeshifter.Computer {
  background-image: url('/images/computer.svg');
  animation-duration: 900ms;
  animation-timing-function: steps(54);
  animation-fill-mode: forwards;
  width: 440px;
  height: 346px;
  background-repeat: no-repeat;
}
.shapeshifter.Computer.play {
  animation-name: playComputer;
}

.Phone-static {
  width: 30%;
}
.Computer-static {
  width: 50%;
}

#Portfolio > div {
  background: linear-gradient(145deg, #2E3243 0%, #2F77D6 100%);
  color: white;
}

#Portfolio .container {
  padding-top: 4vh;
  padding-bottom: 4vh;
}

#Portfolio h1 {
  margin: auto;
  margin-bottom: 20px;
}

#Projects {
  width: 100%;
}
#Projects li {
  width: 60%;
  margin: auto;
  margin-bottom: 10%;
}
#Projects li img {
  width: 100%;
  margin: auto;
  border-radius: 10%;
}

.UofM-logo {
  width: 50%;
  margin: auto;
}

#Companies, #second-companies {
  width: 100%;
  margin: auto;
  display: flex;
}
#Companies {
  margin: 5% 5%;
}
#second-companies {
  margin: auto 10%;
  margin-bottom: 10vh;
}
#Companies li {
  float: left;
  margin: auto 5%;
  width: 33%;
}

#second-companies li {
  float: left;
  margin: auto 5%;
  width: 50%;
  max-height: 15vh;
}

#Companies li img, #second-companies li img {
  max-width: 100%;
  margin: auto;
}

.Toolkit {
  width: 100%;
  margin: auto;
  margin-bottom: 5vh;
}
.Toolkit li {
  float: left;
  width: 15%;
  margin: 2.5%;
}

.Toolkit li img {
  width: 100%;
}

.Final-button {
  margin-top: 40px;
  margin-bottom: 80px;
}

footer {
  padding: 80px 0px;
  background: rgb(51,155,158);
  background: linear-gradient(145deg, #2E3243 0%, #2F77D6 100%);
}

footer .left-text-content p {
  color: #fff;
  font-size: 14px;
}

footer .left-text-content a {
  color: #fff;
}

footer .right-text-content {
  float: right;
}

footer .right-text-content p {
  color: #fff;
  font-size: 14px;
  margin-right: 15px;
  text-transform: uppercase;
}

footer .right-text-content ul li {
  display: inline-block;
}

footer .right-text-content ul li a {
  width: 32px;
  height: 32px;
  display: inline-block;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 50%;
  color: #5fb759;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

footer .right-text-content ul li a:hover {
  background-color: #5fb759;
  color: #fff;
}

@media (max-width: 992px) {
  footer .left-text-content p {
    text-align: center;
    margin-bottom: 30px;
  }
  footer .right-text-content {
    float: none;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .Desktop-only {
    display: block;
  }

  .Mobile-only {
    display: none;
  }
  
  .Button-container a {
    float: left;
    margin: auto 4vw !important;
  }

  .Slogan {
    margin-bottom: 0px;
  }

  #About .container {
    padding: 30vh 0px;
  }
  #About .container .row:first-child {
    margin-bottom: 30vh;
  }
  

  #Projects li {
    width: 20%;
    float: left;
    margin: auto 3%;
  }
  #Projects > :first-child {
    margin-left: 0px;
  } 
  #Projects > :last-child {
    margin-right: 0px;
  }

  #Portfolio .container {
    padding-top: 5vh;
    padding-bottom: 10vh;
  }
  #Portfolio h1 {
    padding-bottom: 5vh;
  }
}