/*
----------------------
HEADER
----------------------
*/

header {
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    min-height: 80px;
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
  }
  
  header.Header--fixed {
    position: fixed;
    background-color: #334AC0;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
  }
  
  header:not(.Header--fixed) {
    position: absolute;
  }
  
  header .main-nav .logo {
    line-height: 80px;
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    float: left;
  }
  
  header .main-nav .Navigation {
    float: right;
    margin-top: 20px;
    margin-right: 0px;
    display: flex;
  }
  
  header .main-nav .Navigation li {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  header .main-nav .Navigation li a {
    font-weight: 500;
    font-size: 13px;
    color: #7a7a7a;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    border: transparent;
    letter-spacing: 1px;
  }
  
  header .main-nav .Navigation li a {
    color: #fff;
  }
  
  header .main-nav .Navigation li a.active {
    border-bottom: solid white 2px;
  }
  
  header .main-nav .Navigation li a.Button,
  a.Button {
    text-transform: none;
    font-weight: 500;
    font-size: large;
    width: 125px;
    text-align: center;
    padding: 10px 16px;
    color: #334AC0 !important;
    background-color: white;
    border-radius: 150px;
    margin: auto;
    margin-left: 5px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.22);
    transition: all .2s;
  }
  
  header .main-nav .menu-trigger {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 23px;
    width: 32px;
    height: 40px;
    text-indent: -9999em;
    z-index: 99;
    right: 40px;
    display: none;
  }
  
  header .main-nav .menu-trigger span,
  header .main-nav .menu-trigger span:before,
  header .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #334AC0;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
  }

  header.Header--fixed .main-nav .menu-trigger span,
  header.Header--fixed .main-nav .menu-trigger span:before,
  header.Header--fixed .main-nav .menu-trigger span:after {
    background-color: white;
  }
  
  header .main-nav .menu-trigger span:before,
  header .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #334AC0;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
    width: 75%;
  }
  
  header .main-nav .menu-trigger span:before,
  header .main-nav .menu-trigger span:after {
    content: "";
  }
  
  header .main-nav .menu-trigger span {
    top: 16px;
  }
  
  header .main-nav .menu-trigger span:before {
    -moz-transform-origin: 33% 100%;
    -ms-transform-origin: 33% 100%;
    -webkit-transform-origin: 33% 100%;
    transform-origin: 33% 100%;
    top: -10px;
    z-index: 10;
  }
  
  header .main-nav .menu-trigger span:after {
    -moz-transform-origin: 33% 0;
    -ms-transform-origin: 33% 0;
    -webkit-transform-origin: 33% 0;
    transform-origin: 33% 0;
    top: 10px;
  }
  
  @media (max-width: 768px) {
    header:not(.Header--fixed) .main-nav .logo {
      color: #334AC0;
    }
    
    header .main-nav .Navigation li a.active {
      color: #334AC0 !important;
    }
    header {
      background-color: #f7f7f7 !important;
      padding: 0px 15px;
      height: 80px;
      box-shadow: none;
      text-align: center;
    }
    header .container {
      padding: 0px;
    }
    header .menu-trigger {
      display: block !important;
    }
    header .main-nav .Navigation {
      float: none;
      width: 100%;
      display: none;
      -webkit-transition: all 0s ease 0s;
      -moz-transition: all 0s ease 0s;
      -o-transition: all 0s ease 0s;
      transition: all 0s ease 0s;
      margin-left: 0px;
    }
    header .main-nav .Navigation li:first-child {
      border-top: 1px solid #eee;
    }
    header .Navigation {
      margin-top: 80px !important;
    }
    header .main-nav .Navigation li {
      width: 100%;
      background: #f7f7f7;
      border-bottom: 1px solid #eee;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    header .main-nav .Navigation li a {
      height: 50px !important;
      display: block;
      line-height: 50px !important;
      padding: 0px !important;
      border: none !important;
      background: #f7f7f7 !important;
      color: #334AC0 !important;
    }
    header .main-nav .Navigation li:hover {
      background: #334AC0 !important;
    }
    header .main-nav .Navigation li:hover > a{
      background: #334AC0 !important;
      color: white !important;
    }
    header .main-nav .Navigation li:last-child {
      display: none;
    }
    header .main-nav {
      overflow: hidden;
    }
  }