.portfolio-container {
    
    max-width: 1000px;
    margin: 0px auto;
    margin-top: 124px;
}

.project-container {
    display: grid;
    gap: 48px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.project {
    margin-bottom: 24px;
    margin-left: 15px;
    margin-right: 15px;
}

.project-image-link {
    margin-bottom: 16px;
    display: block;
}

.project-image {
    width: 100%;
    padding-top: 66.66%;
    position: relative;
}

.project-image > div {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 15px 12px -8px;
    border: 1px solid rgb(247, 248, 248);
    cursor: pointer;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;

    border-radius: 8px;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
}

.project-title-container {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 16px;
}

.project-title {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.project-tags {
    display: flex;
}

.project-tags > a {
    display: inline;
    color: inherit;
    box-shadow: none;
    text-decoration: none;
    cursor: pointer;
}

.project-tags > a > span {
    border-radius: 4px;
    font-size: 11px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 5px 7px;
    cursor: pointer;
    margin: 0px 4px;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    background: rgb(136, 195, 18);
    color: white;
}

.project-tabs
{
    margin-bottom: 16px;
    color: rgb(85, 85, 85);
    font-size: 11px;
    display: flex;
}

.project-tabs > :not(:last-child) {
    margin-right: 8px;
}

.project-tabs > span {
    padding: 4px 8px;
    font-size: 10px;
    font-weight: 500;
    border-radius: 4px;
    background-color: rgb(236, 239, 245);
    text-transform: uppercase;
    color: rgb(62, 69, 81);
    display: block;
    transition: background 0.1s ease-in 0s;
}

.privacypal-image {
    background: url(/../images/portfolio/privacypal.png) center top / cover;
}

.eatseeks-image {
    background: url(/../images/portfolio/eatseeks.png) center top / cover;
}

.imd-image {
    background: url(/../images/portfolio/imd.png) center top / cover;
}

.pianoriver-image {
    background: url(/../images/portfolio/pianoriver.png) center top / cover;
}

.gnop-image {
    background: url(/../images/portfolio/gnop-showcase.png) center top / cover;
}

.coinking-image {
    background: url(/../images/portfolio/coin-king-showcase.png) center top / cover;
}

.amato-image {
    background: url(/../images/portfolio/amato-mockups.png) center top / cover;
}

.intvo-image {
    background: url(/../images/portfolio/intvo-mockups.png) center top / cover;
}

.tidal-image {
    background: url(/../images/portfolio/tidal-showcase.png) center top / cover;
}

.nest-egg-image {
    background: url(/../images/portfolio/nest-egg-showcase.png) center top / cover;
}

.nightmare-mine-image {
    background: url(/../images/portfolio/nightmare-mine-showcase.png) center top / cover;
}

.ir-image {
    background: url(/../images/portfolio/ir-showcase.png) center top / cover;
}
